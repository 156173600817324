import React from 'react'

import styles from '../../assets/styles/card.module.scss'


interface Props {
	noPadding?: boolean
	className?: string
}


const CardFooter: React.FunctionComponent<Props> = ({
	children,
	className,
}) => (
	<div className={`${styles.cardFooter} ${className || ''}`}>
		{children}
	</div>
)

export default CardFooter
