export interface OrderedReceiptItem {
	name: string,
	unitPrice: number
	quantity: number
}

export interface Discount {
	amount: number
	promoCode: string
	percent_off?: number
	amount_off?: number
	name: string
}

export interface ReceiptData {
	[key: string]: any

	orderDate: string,
	items?: OrderedReceiptItem[],
	total: number
	subTotal: number
	orderId: string

	discounts?: Discount[]
}




const formatDate = (dateValue: string | number): string => {
	const date = new Date(dateValue)

	return date.toLocaleDateString('en-se', { day: 'numeric', month: 'short', year: 'numeric' })
}

const formatPaypalOrderItem = (item: Record<string, any>): OrderedReceiptItem => {
	return {
		name: item.name,
		quantity: parseInt(item.quantity),
		unitPrice: parseInt(item.unit_amount.value.replace('.', '')),
	}}

const formatStripeOrderItem = (item: Record<string, any>): OrderedReceiptItem => ({
	name: item.description,
	quantity: item.quantity,
	unitPrice: item.price.unit_amount,
})

const formatPaypalData = (data: Record<string, any>): ReceiptData => {	
	return {
		data,
		orderDate: formatDate(data.create_time),
		items: data.purchase_units[0].items.map(formatPaypalOrderItem),
		total: parseInt(data.purchase_units[0].amount.breakdown.item_total.value.replace('.', ''), 10),
		subTotal: parseInt(data.purchase_units[0].amount.breakdown.item_total.value.replace('.', ''), 10),
		orderId: data.id,
	}
}

const formatStripeDiscounts = (data: Record<string, any>[]): Discount[] => {
	return data.map(item => ({
		amount: item.amount,
		name: item.discount.coupon.name,
		promoCode: item.discount.promotion_code,
		amount_off: item.discount.coupon.amount_off,
		percent_off: item.discount.coupon.percent_off,
	}))
}

const formatStripeData = (data: Record<string, any>): ReceiptData => {
	return {
		data,
		orderDate: formatDate(data.session.payment_intent.created * 1000),
		items: data.session.line_items.data.map(formatStripeOrderItem),
		total: data.session.amount_total,
		subTotal: data.session.amount_subtotal,
		orderId: data.session.client_reference_id,
		discounts: formatStripeDiscounts(data.session.total_details.breakdown.discounts),
	}
}

export const formatReceiptData = (data: Record<string, any>, type: 'paypal' | 'stripe'): ReceiptData | undefined => {
	if (type === 'paypal') return formatPaypalData(data.order)
	if (type === 'stripe') return formatStripeData(data)
}
