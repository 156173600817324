import React from 'react'
import Card from '../components/Card/Card'
import CardContent from '../components/Card/CardContent'
import CardHeader from '../components/Card/CardHeader'
import Col from '../components/Grid/Col'
import Row from '../components/Grid/Row'

import StorePage from '../components/misc/StorePage'
import { H4, Text, H5 } from '../components/Typography'
import headerImage from '../assets/images/bg.png'
import styles from '../assets/styles/PageHeader.module.scss'
import CardFooter from '../components/Card/CardFooter'



const FaQPage: React.FunctionComponent = () => {
	return (
		<StorePage>
			<Row style={{ height: '25vh' }} className={'halign-text-center valign-text-middle'}>
				<Col style={{ padding: 0, height: '100%' }}>
					<header style={{ backgroundImage: `url(${headerImage})` }} className={styles.header}>
						<h1>Donations</h1>
					</header>
				</Col>
			</Row>
			<Row>
				<Col>
					<H5 thin><a href="../"><i className={'ti-arrow-left'} style={{ verticalAlign: 'bottom' }}></i></a> Discord Dungeons Donations</H5>
				</Col>
			</Row>
			<Row around>
				<Col sm={12} md={9} xl={9} style={{ margin: '0 auto' }}>
					<Card>
						<CardHeader>
							<H5>Discord Dungeons Donations - Frequently asked Questions</H5>
						</CardHeader>
						
						<CardContent>
							<Row>
								<Col>
									<H4>What is "within reason" for an item?</H4>
									<Text>
										"Within reason" means that the items stats are based on the level of the item itself.
										This is to prevent the game from becoming pay to win with donator weapons having damage ranges of e.g. 200 to 6,000 at level 5.
									</Text>
								</Col>
							</Row>

							<Row>
								<Col>
									<H4>What effects can I have on my item and what stats can I get?</H4>
									<Text>
										On an item, you can get all the attributes that are in the game, which can be listed using the <code>#!attribs</code> command.
										<br />
										The maximum stats you can have set on an item is 30%, which means 1% per 10 levels. (Does not apply to Strength and Crits)
										<br />
									
										<ul>
											<li>Strength Boost has a maximum of 30% as a collective for each item you apply it on, this means if your helmet has 10% Strength boost and your Weapon has 20% Strength Boost you've used up your collective total.</li>
											<li>Crit Boost has a maximum of 15% as a collective for each item you apply it on, this means if your helmet has 10% Crit Boost and your Weapon has 5% Crit Boost you've used up your collective total.</li>
											<li>Potions have a maximum of 1.25x multiplier.</li>
											<li>Rings have a maximum of 1.5x multiplier.</li>
										</ul>
										<br />

										Along with the above totals, we'll also take previous items from a single user into account when calculating the collective total that's been used up.
									</Text>
								</Col>
							</Row>

							<Row>
								<Col>
									<H4>What stats will my donator pet have?</H4>
									<Text>
										The stats of a donator pet is typically matched to the users current level +10, with the stats getting a 20% boost to HP and damage.
										Along with this, the user gets to choose the type of the pet aswell.
									</Text>
								</Col>
							</Row>

							<Row>
								<Col>
									<H4>What's Membership?</H4>
									<Text>
										Membership is a premium version of DRPG, giving the user exclusive content.
										<Text thick> Included with membership is</Text>
										<ul>
											<li>More market slots</li>
											<li>More Quests</li>
											<li>More Areas</li>
											<li>More Items</li>
											<li>More Enemies</li>
											<li>More NPCs</li>
										</ul>
									</Text>
								</Col>
							</Row>
							
						</CardContent>

						<CardFooter>
							<Row><Col>Last Edited: November 13, 2021</Col></Row>
						</CardFooter>
					</Card>
				</Col>
			</Row>
		</StorePage>
	)
}

export default FaQPage
