import React from 'react'
import Content from '../Layout/Content'
import Layout from '../Layout/Layout'


interface StorePageProps {
	title?: string,
}

const StorePage: React.FunctionComponent<StorePageProps> = ({
	//title,
	children,
}) => {
	return (
		<>
			{/* <Head>
				<title>{title}</title>
				<meta name="description" content="Generated by create next app" />
				<link rel="icon" href="/favicon.ico" />
			</Head> */}

			<Layout>
				<Content noPadding>
					{children}
				</Content>
			</Layout>
		</>
	)
}

export default StorePage
