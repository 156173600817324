import React from 'react'

interface Props {
	icon: string,
}

const DonationReward: React.FunctionComponent<Props> = ({
	icon,
	children,
}) =>(
	<div>
		<span className='reward-icon'>
			<i className={icon}></i>
		</span>
		<span>
			{children}
		</span>
	</div>
)


export default DonationReward
