import React, { CSSProperties } from 'react'

import styles from '../../assets/styles/card.module.scss'

interface Props {
	noPadding?: boolean
	style?: CSSProperties
}


const CardContent: React.FunctionComponent<Props> = ({
	children,
	style,
}) => (
	<div className={styles.cardContent} style={style}>
		{children}
	</div>
)

export default CardContent
