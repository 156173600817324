import React from 'react'

import styles from '../assets/styles/CartItem.module.scss'
import { H5, Span } from './Typography'

interface Props {
	name: string
	quantity: number
	price: number
	onRemove: () => void
	onSetItemQuantity: (qty: number | string) => void
}

const CartItem: React.FunctionComponent<Props> = ({
	name,
	quantity,
	price,
	onRemove,
	onSetItemQuantity,
}) => {
	return (
		<div className={styles.container}>
			<div className={styles.left}>
				<img className={styles.productImage} src={'https://res.discorddungeons.me/icon.png'} alt={'item'} />
			</div>
			<div className={styles.right}>
				<div className={styles.title}>
					<div className={styles.titleText}>
						<H5>{name}</H5>
					</div>
					<div className={styles.titleAction} onClick={onRemove}>
						<H5><i className={'ti-close'}></i></H5>
					</div>
				</div>
				<div className={styles.description}>
					<div className={styles.cost}>
						<Span>$ {(price/100).toFixed(2)}</Span>
					</div>
				</div>
				<div className={styles.bottom}>
					<div className={styles.actions}>
						<div className={styles.actionsLeft}>
							<button onClick={() => onSetItemQuantity(quantity - 1)}>-</button>
						</div>
						<div className={styles.actionsMiddle}>
							<input
								type={'number'}
								className={`${styles.spinnerInput}`}
								value={quantity}
								min={0}
								step={1}
								onChange={e => onSetItemQuantity(e.target.value)}
							/>
						</div>
						<div className={styles.actionsRight}>
							<button onClick={() => onSetItemQuantity(quantity + 1)}>+</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CartItem
