import React, { CSSProperties } from 'react'

import styles from '../../assets/styles/card.module.scss'


interface Props {
	noPadding?: boolean
	image?: string
	className?: string
	style?: CSSProperties
}


const CardHeader: React.FunctionComponent<Props> = ({
	children,
	image,
	className,
	style,
}) => (
	<div className={`${styles.cardHeader} ${className || ''}`} style={style}>
		{image && <img src={image} alt={'CARD'} className={styles.cardHeaderImage} /> }
		<div className={styles.cardHeaderContent}>
			{children}
		</div>
	</div>
)

export default CardHeader
