export const getReceipt = async (sessionId: string): Promise<Record<string, any>> => {
	let response
	if (sessionId.startsWith('pp_')) {
		response = await fetch(`/api/getPayPalSession/${sessionId.replace(/^pp_/, '')}`)
	} else {
		response = await fetch(`/api/getStripeSession/${sessionId}`)
	}

	if (!response.ok) {
		throw response
	}
		
		
	return await response.json()
}
