import React from 'react'
import DonationReward from './DonationReward'

interface Props {
	length: string
}

const MembershipReward: React.FunctionComponent<Props> = ({
	length,
}) => (
	<>
		<DonationReward icon={'ti-pin'}>
			{length.replace('D', '')} days of membership
		</DonationReward>
		<DonationReward icon={'ti-pin'}>
			Exclusive Locations
		</DonationReward>
		<DonationReward icon={'ti-pin'}>
			Exclusive Quests
		</DonationReward>
		<DonationReward icon={'ti-pin'}>
			Exclusive Items
		</DonationReward>
	</>
)


export default MembershipReward
