import styles from '../assets/styles/StripePayButton.module.scss'

import React from 'react'

interface Props {
	onClick?: () => void,
}

const StripePayButton: React.FunctionComponent<Props> = ({
	onClick,
}) => {
	return (
		<button
			className={styles.stripeButton}
			onClick={onClick}
		>
			Pay
		</button>
	)
}

export default StripePayButton
