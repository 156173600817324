import React from 'react'

import Row from './Grid/Row'

import styles from '../assets/styles/QuantityInput.module.scss'

interface Props {
	onSetValue: (value: string | number) => void,
	qty: number
}

const QuantityInput: React.FunctionComponent<Props> = ({
	onSetValue,
	qty,
}) => {
	return (
		<Row style={{ height: '100%' }}>
			<div className={styles.container}>
				<div className={styles.left}>
					<button
						className={`${styles.spinnerButton}`}
						data-type="subtract"
						title="Subtract 1"
						aria-controls="spinner-input"
						onClick={() => onSetValue(qty - 1)}
					>
						-
					</button>
				</div>
				<div className={styles.middle}>
					<input
						type={'number'}
						className={`${styles.spinnerInput}`}
						value={qty}
						min={0}
						step={1}
						onChange={e => onSetValue(e.target.value)}
					/>
				</div>
				<div className={styles.right}>
					<button
						className={`${styles.spinnerButton}`}
						data-type="subtract"
						title="Subtract 1"
						aria-controls="spinner-input"
						onClick={() => onSetValue(qty + 1)}
					>
						+
					</button>
				</div>
			</div>
		</Row>
	)
}

export default QuantityInput
