import React from 'react'
import DonationReward from './DonationReward'

const ContactMackan: React.FunctionComponent = () =>(
	<DonationReward icon={'ti-pin'}>
		Be sure to contact Mackan#7196 after you've donated!
	</DonationReward>
)


export default ContactMackan
