import styles from '../assets/styles/Cart.module.scss'

import tiers from '../assets/data/tiers.json'
import liveTiers from '../assets/data/tiers-live.json'

import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import Row from './Grid/Row'
import CartItem from './CartItem'
import Col from './Grid/Col'
import StripePayButton from './StripePayButton'

interface Props {
	removeCartItem: (itemId: string) => void
	setCartQuantity: (itemId: string, quantity: number | string) => void
	onCreateStripeOrder: () => void
	items: Record<string, number>
}

const Cart: React.FunctionComponent<Props> = ({
	removeCartItem,
	setCartQuantity,
	items,
	onCreateStripeOrder,
}) => {
	const [ isOpen, setIsOpen ] = useState(false)
	const [ animateIcon, setAnimateIcon ] = useState(false)

	useEffect(() => {
		setAnimateIcon(true)
	}, [items])

	const getTotalItemCount = (): number => {
		return Object.keys(items).reduce((acc, current) => acc + items[current], 0)
	}

	const donationTiers = window.__env.NODE_ENV === 'development' ? tiers : liveTiers

	const getTotalCost = (): number => {
		return Object.keys(items).reduce((acc, itemId) => {
			const currentTier = donationTiers.find(tier => tier.stripeProduct === itemId)

			if (!currentTier) return acc

			return acc + currentTier.cost * items[itemId]
		}, 0)
	}

	const totalItemCount = getTotalItemCount()

	return (
		<div className={styles.cartWrapper}>
			<div
				className={classNames(styles.cartInner, 'shadow-2xl', {
					[styles.isOpen]: isOpen,
				})}
			>
				<div className={styles.header}>
					<header>
						<Row>
							<div className={styles.headerInner}>
								<span>
									<h3>Cart</h3>
									<span>
										<div>
											<span>{totalItemCount} Items</span>
											<span>$ {(getTotalCost() / 100).toFixed(2)} Total</span>
										</div>
									</span>
								</span>
								<span>
								</span>
							</div>
						</Row>
					</header>
				</div>
				<div className={styles.content}>
					

					{Object.keys(items).map(itemId => {
						const currentTier = donationTiers.find(tier => tier.stripeProduct === itemId)

						if (!currentTier) return null
						
						return (
							<CartItem
								quantity={items[itemId]}
								name={currentTier?.name}
								price={currentTier.cost}
								onRemove={() => removeCartItem(itemId)}
								onSetItemQuantity={(e) => setCartQuantity(itemId, e)}
								key={itemId}
							/>
						)
					})
					}
				</div>
				<div className={styles.footer}>
					<Row>
						<Col sm={12} md={12} style={{ padding: 0 }} className={styles.stripeButtonCol}>
							<StripePayButton
								onClick={onCreateStripeOrder}
							/>
						</Col>
					</Row>
				</div>
			</div>
			<div className={styles.cartButton} onClick={() => setIsOpen(!isOpen)}>
				<a
					className={classNames('btn-floating btn-large', {
						animated: false,
					})}
					
					href="#ref"
				>
					<div
						className={`${styles.icons} ${isOpen ? styles.isOpen : ''} ${animateIcon ? styles.animated : ''}`}
						onAnimationEnd={() => setAnimateIcon(false)}
					>
						<i
							className={classNames(styles.iconDefault, {
								'ti-shopping-cart': totalItemCount === 0,
								'ti-shopping-cart-full': totalItemCount > 0,
								
							})}
						></i>
						<i className={`ti-close ${styles.iconHover}`}></i>
					</div>
				</a>
			</div>
		</div>
	)
}

export default Cart
