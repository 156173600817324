import React, { useRef, useState } from 'react'

import tiers from '../assets/data/tiers.json'
import liveTiers from '../assets/data/tiers-live.json'
import Row from '../components/Grid/Row'
import Col from '../components/Grid/Col'

import headerImage from '../assets/images/bg.png'

import styles from '../assets/styles/PageHeader.module.scss'
import footerStyles from '../assets/styles/Footer.module.scss'
import StorePage from '../components/misc/StorePage'
import DonationTier from '../components/DonationTier'
import Cart from '../components/Cart'
import { createStripeSession } from '../util/StripeUtils'
import { H6 } from '../components/Typography'


const StoreFront: React.FunctionComponent = () => {
	const [ cartItems, setCartItems ] = useState<Record<string, number>>({})

	const cartItemsRef = useRef<Record<string, number>>({})

	cartItemsRef.current = cartItems

	const donationTiers = window.__env.NODE_ENV === 'development' ? tiers: liveTiers

	const addCartItems = (itemId: string, quantity: number) => {
		setCartItems({
			...cartItems,
			[itemId]: (cartItems[itemId] || 0) + quantity,
		})
	}

	const onRemoveCartItem = (itemId: string) => {
		const newCartItems = {
			...cartItems,
		}

		delete newCartItems[itemId]


		setCartItems(newCartItems)
	}

	const setCartQuantity = (itemId: string, quantity: number | string) => {
		let setValue = 0

		if (typeof quantity === 'number') {
			setValue = quantity
		} else {
			setValue = parseInt(quantity, 10)
			
		}
		
		if (Number.isNaN(setValue)) setValue = 0

		setCartItems({
			...cartItems,
			[itemId]: Math.max(setValue, 0),
		})
	}

	return (
		<StorePage>
			<Row style={{ height: '25vh' }} className={'halign-text-center valign-text-middle'}>
				<Col style={{ padding: 0, height: '100%' }}>
					<header style={{ backgroundImage: `url(${headerImage})` }} className={styles.header}>
						<h1>Donations</h1>
					</header>
				</Col>
			</Row>
			<Row style={{ backgroundColor: '#F3F7F9' }}>
				<Col sm={12} lg={8} lgOffset={2}>
					<Row>
						{donationTiers.map(tier => (
							<DonationTier
								{...tier}
								key={tier.id}
								isLoggedIn={true}
								addToCart={addCartItems}
							/>
						))}
					</Row>
				</Col>
			</Row>
			<Row style={{ backgroundColor: '#3C9E94', textAlign: 'center' }} center>
				<footer style={{ width: '100%', padding: 8 }} className={footerStyles.footer}>
					<H6 white>
							Made by <a href="https://mackan.xyz">Mackan</a>
							| Discord Dungeons and DRPG &copy; <a href="https://mackan.xyz">Mackan</a>
							| <a href="Terms">Terms</a> - <a href="FaQ">FAQ</a> - <a href="Privacy">Privacy Policy</a>
					</H6>
				</footer>
			</Row>
			<Cart
				removeCartItem={onRemoveCartItem}
				setCartQuantity={setCartQuantity}
				items={cartItems}
				onCreateStripeOrder={() => createStripeSession(cartItemsRef.current)}
			/>
		</StorePage>
	)
}

export default StoreFront
