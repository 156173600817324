import React from 'react'
import { formatNumber } from '../../util/FormatNumber'
import DonationReward from './DonationReward'

interface Props {
	gold: number
}

const GoldReward: React.FunctionComponent<Props> = ({
	gold,
}) =>(
	<DonationReward icon={'ti-tag'}>
		{formatNumber(gold)} gold!
	</DonationReward>
)


export default GoldReward
