import React, { useState } from 'react'

import placeholderImage from '../assets/images/Placeholder.png'
import Col from './Grid/Col'

import CardHeader from './Card/CardHeader'
import Card from './Card/Card'
import CardContent from './Card/CardContent'
import CardFooter from './Card/CardFooter'
import Row from './Grid/Row'
import QuantityInput from './QuantityInput'

import styles from '../assets/styles/DonationsTier.module.scss'
import DonatorTag from './DonationRewards/DonatorTag'
import LegalJargon from './DonationRewards/LegalJargon'
import GoldReward from './DonationRewards/GoldReward'
import ContactMackan from './DonationRewards/ContactMackan'
import ItemReward from './DonationRewards/ItemReward'
import PetReward from './DonationRewards/PetReward'
import MembershipReward from './DonationRewards/MembershipReward'

interface Props {
	name: string
	cost_string: string
	reward_strings: string[]
	cost: number
	isLoggedIn: boolean
	stripeProduct: string
	rewards: Record<string, any>,
	addToCart: (itemId: string, quantity: number) => void
}

const DonationTier: React.FunctionComponent<Props> = ({
	name,
	cost_string,
	rewards,
	stripeProduct,
	addToCart,
}) => {
	const [ qty, setQty ] = useState(1)

	const onSetValue = (value: string | number) => {
		let setValue = 0

		if (typeof value === 'number') {
			setValue = value
		} else {
			setValue = parseInt(value, 10)
			
		}
		
		if (Number.isNaN(setValue)) setValue = 0
		
		setQty(Math.max(setValue, 0))
	}

	const mapRewardStrings = () => {
		const constructElement = (element: string) => {
			switch (element) {
				case 'tag':
					return <DonatorTag />
				case 'gold':
					return <GoldReward gold={rewards['gold']} />
				case 'contact':
					return <ContactMackan />
				case 'item':
					return <ItemReward />
				case 'pet':
					return <PetReward />
				case 'membership':
					return <MembershipReward length={rewards['membership']} />
			}
		}

		return Object.keys(rewards).map(constructElement)
	}

	return (
		<Col sm={12} md={4} lg={4}>
			<Card style={{ height: '100%' }}>
				<CardHeader image={placeholderImage}>
					<h5 className={'card-title'}>{name} - ${cost_string}</h5>
				</CardHeader>
				<CardContent>
					<div className={'card-content donate-rewards'}>
						{mapRewardStrings()}
						<LegalJargon />
					</div>
				</CardContent>
				<CardFooter>
					<Row>
						<div className={styles.container}>
							<div className={styles.left}>
								<QuantityInput qty={qty} onSetValue={onSetValue} />
							</div>
							<div className={styles.right}>
								<button className={styles.button} onClick={() => addToCart(stripeProduct, qty)}>
									<i className={'ti-shopping-cart'} />
									Add to cart
								</button>
							</div>
						</div>
					</Row>
				</CardFooter>
			</Card>
		</Col>
	)
}

export default DonationTier
