import React from 'react'
import DonationReward from './DonationReward'

const ItemReward: React.FunctionComponent = () => (
	<>
		<DonationReward icon={'ti-pin'}>
			Have a custom item made for you! (Within reason.)
		</DonationReward>
		<DonationReward icon={'ti-pin'}>
			Alternatively, you can trade the item for 50,000 gold.
		</DonationReward>
	</>
)


export default ItemReward
