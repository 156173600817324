import React from 'react'
import DonationReward from './DonationReward'

const DonatorTag: React.FunctionComponent = () =>(
	<DonationReward icon={'ti-tag'}>
		Get a donator tag next to your name!
	</DonationReward>
)


export default DonatorTag
