import React from 'react'
import DonationReward from './DonationReward'

const LegalJargon: React.FunctionComponent = () =>(
	<DonationReward icon={'ti-pin'}>
		By donating you agree to the <a href='/Terms'>terms</a> and the <a href='/Privacy'>Privacy Policy</a>
	</DonationReward>
)


export default LegalJargon
