import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(window.__env.REACT_APP_STRIPE_PUBLIC_KEY as string)

export const createStripeSession = async (items: Record<string, number>): Promise<void> => {

	const stripe = await stripePromise

	if (!stripe) {
		console.error("Stripe couldn't be initialized")
		return
	}

	const response = await fetch('/api/createCheckoutSession', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			items,
		}),
	})

	const session = await response.json()

	const result = await stripe.redirectToCheckout({
		sessionId: session.id,
	})

	if (result.error) {
		console.error('ERROR!', result.error)
	}
}
