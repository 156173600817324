import classNames from 'classnames'
import React from 'react'

import styles from '../../assets/styles/layout.module.scss'


interface Props {
	noPadding?: boolean
}


const Content: React.FunctionComponent<Props> = ({
	children,
	noPadding,
}) => (
	<div className={classNames(styles.content, {
		[styles.noPadding]: noPadding,
	})}>
		{children}
	</div>
)

export default Content
