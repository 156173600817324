import classNames from 'classnames'
import React from 'react'

interface TypographyColorProps {
	/** Makes the text have a light color */
	light?: boolean
	/** Makes the text have the success color */
	success?: boolean
	dark?: boolean
	white?: boolean
}

interface TypographyWeightProps {
	/** Makes the text thin */
	thin?: boolean,
	/** Makes the text thick */
	thick?: boolean,
}

type TypographyBaseProps = TypographyColorProps & TypographyWeightProps



export const H1: React.FunctionComponent<TypographyBaseProps> = ({ children, ...props }) => <h1 className={classNames(props)}>{children}</h1>
export const H2: React.FunctionComponent<TypographyBaseProps> = ({ children, ...props }) => <h2 className={classNames(props)}>{children}</h2>
export const H3: React.FunctionComponent<TypographyBaseProps> = ({ children, ...props }) => <h3 className={classNames(props)}>{children}</h3>
export const H4: React.FunctionComponent<TypographyBaseProps> = ({ children, ...props }) => <h4 className={classNames(props)}>{children}</h4>
export const H5: React.FunctionComponent<TypographyBaseProps> = ({ children, ...props }) => <h5 className={classNames(props)}>{children}</h5>
export const H6: React.FunctionComponent<TypographyBaseProps> = ({ children, ...props }) => <h6 className={classNames(props)}>{children}</h6>

export const Text: React.FunctionComponent<TypographyBaseProps> = ({ children, ...props }) => <p className={classNames(props)}>{children}</p>
export const Span: React.FunctionComponent<TypographyBaseProps> = ({ children, ...props }) => <span className={classNames(props)}>{children}</span>
