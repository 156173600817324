import React, { CSSProperties } from 'react'

import styles from '../../assets/styles/card.module.scss'


interface Props {
	noPadding?: boolean
	style?: CSSProperties
	className?: string
}


const Card: React.FunctionComponent<Props> = ({
	children,
	style,
	className,
}) => (
	<div className={`${styles.card} ${className ?? ''}`} style={style}>
		{children}
	</div>
)

export default Card
