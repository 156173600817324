import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import React from 'react'
import StoreFront from './views/StoreFront'
import SuccessPage from './views/SuccessPage'

import './assets/styles/index.scss'
import TermsPage from './views/TermsPage'
import PrivacyPage from './views/PrivacyPage'
import FaQPage from './views/FaQPage'

const App: React.FunctionComponent = () =>(
	<Router>
		<Switch>
			<Route path='/' exact component={StoreFront} />
			<Route path='/success/:sessionId' exact component={SuccessPage} />
			<Route path='/Terms' component={TermsPage} />
			<Route path='/Privacy' component={PrivacyPage} />	
			<Route path='/FaQ' component={FaQPage} />	
		</Switch>
	</Router>
)


export default App
