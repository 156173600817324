import React from 'react'
import Col from './Grid/Col'
import Row from './Grid/Row'

interface Props {
	name: string
	unitPrice: number
	quantity: number
}

const ReceiptItem: React.FunctionComponent<Props> = ({
	name,
	unitPrice,
	quantity,
}) => {
	return (
		<Row className={'receipt-item-row'}>
			<Col sm={2}>
				<img style={{ width: '100%' }} src={'https://res.discorddungeons.me/icon.png'} alt={'item'} />
			</Col>
			<Col sm={3}>{name}</Col>
			<Col>Qty {quantity}</Col>
			<Col>${((unitPrice * quantity) / 100)} USD</Col>
		</Row>
	)
}

export default ReceiptItem
