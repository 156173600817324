import React, { useEffect, useState } from 'react'
import Col from '../components/Grid/Col'
import Row from '../components/Grid/Row'
import StorePage from '../components/misc/StorePage'

import styles from '../assets/styles/SuccessPage.module.scss'

import placeholderImage from '../assets/images/Placeholder.png' 
import Card from '../components/Card/Card'
import CardHeader from '../components/Card/CardHeader'
import CardContent from '../components/Card/CardContent'
import {  Text, Span, H3 } from '../components/Typography'
import { match } from 'react-router'
import { getReceipt } from '../util/ReceiptUtils'
import { Discount, formatReceiptData, ReceiptData } from '../util/ReceiptFormatter'
import CardFooter from '../components/Card/CardFooter'
import ReceiptItem from '../components/ReceiptItem'
import Loader from 'react-loader-spinner'

interface ParamsMatch extends match {
	params: {
		sessionId: string,
	}
}



interface Props {
	match: ParamsMatch,
}


const DiscountRow: React.FunctionComponent<Discount> = ({
	amount,
	amount_off,
	percent_off,
}) => {
	if (percent_off !== undefined && percent_off !== null) {
		return <Span success>- {percent_off}% (${(amount / 100).toFixed(2)})</Span>
	} else if (amount_off !== undefined && amount_off !== null) {
		return <Span>- ${(amount_off / 100).toFixed(2)}</Span>
	}

	return null
}


const SuccessPage: React.FunctionComponent<Props> = ({
	match: {
		params: {
			sessionId,
		},
	},
}) => {
	

	const [ receiptData, setReceiptData ] = useState<ReceiptData | undefined>(undefined)

	const [ isLoading, setIsLoading ] = useState<boolean>(true)
	const [ error, setError ] = useState<string | undefined>(undefined)
	
	useEffect(() => {
		const fetchData = async () => {
			if (receiptData === undefined) {
				const fetchedData = await getReceipt(sessionId)				
				setReceiptData(formatReceiptData(fetchedData, sessionId.startsWith('pp_') ? 'paypal' : 'stripe'))

				setIsLoading(false)

			}
		}


		fetchData().catch(e => {
			setIsLoading(false)

			if (e instanceof Response) {
				console.log('response error', e)

				switch (e.status) {
					case 404:
						setError('Receipt not found.')
						return
					case 500:
						setError('Could not connect to server')
						return
					default:
						setError('Could not connect to server')

				}
				return
			}

			if (e instanceof TypeError) {
				
				// Network error
				console.error(e)

				setError("Couldn't connect to server.")
			} else {
				setError(e)
			}
		})
	}, [ receiptData, sessionId ])

	if (isLoading) {
		return (
			<Row style={{ marginBottom: '3rem' }}>
				<Col sm={12} md={6} lg={5} className={styles.container} style={{ padding: 0 }}>
					<Card className={styles.receiptCard}>
						<CardHeader image={placeholderImage} className={styles.cardHeader} />
						<CardContent style={{ padding: '1rem', textAlign: 'center' }}>
							<Loader type="TailSpin" color='#4D9DE0' />
						</CardContent>
						<CardFooter className={styles.footer}>
							<Row>
								<Col sm={8} style={{ padding: 0 }}>
									<Text>Questions? Contact our <a href="mailto:support@discorddungeons.me">Customer Support</a></Text>
								</Col>
								<Col sm={4} style={{ padding: 0 }}>
									<Text light>&copy; 2021 Discord Dungeons</Text>
								</Col>
							</Row>
						</CardFooter>
					</Card>
				</Col>
			</Row>
		)
	}

	if (!isLoading && error) {
		return (
			<Row style={{ marginBottom: '3rem' }}>
				<Col sm={12} md={6} lg={5} className={styles.container} style={{ padding: 0 }}>
					<Card className={styles.receiptCard}>
						<CardHeader image={placeholderImage} className={styles.cardHeader} />
						<CardContent style={{ padding: '1rem', textAlign: 'center' }}>
							{error}
						</CardContent>
						<CardFooter className={styles.footer}>
							<Row>
								<Col sm={8} style={{ padding: 0 }}>
									<Text>Questions? Contact our <a href="mailto:support@discorddungeons.me">Customer Support</a></Text>
								</Col>
								<Col sm={4} style={{ padding: 0 }}>
									<Text light>&copy; 2021 Discord Dungeons</Text>
								</Col>
							</Row>
						</CardFooter>
					</Card>
				</Col>
			</Row>
		)
	}

	return (
		<StorePage>
			<Row style={{ marginBottom: '3rem' }}>
				<Col sm={12} md={6} lg={5} className={styles.container} style={{ padding: 0 }}>
					<Card className={styles.receiptCard}>
						<CardHeader image={placeholderImage} className={styles.cardHeader}>
							<H3>Thank you for your order!</H3>
						</CardHeader>
						<CardContent style={{ padding: '1rem' }}>
							<Row>
								<Row>
									<Col sm={6} style={{ paddingBottom: 0 }}>
										<Span light>Order Date</Span>
									</Col>
									<Col sm={6} style={{ paddingBottom: 0 }}>
										<Span light>Order ID</Span>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Span>{receiptData?.orderDate}</Span>
									</Col>
									<Col sm={6}>
										<Span>{receiptData?.orderId}</Span>
									</Col>
								</Row>
							</Row>
							<hr />
							<Row>
								{receiptData?.items?.map(item => (
									<ReceiptItem
										{...item}
									/>
								))}
							</Row>
							<hr />
							<Row>
								<Row>
									<Col sm={9}>
										Subtotal
									</Col>
									<Col sm={3}>
										$ {((receiptData?.subTotal || 0) / 100).toFixed(2)}
									</Col>
								</Row>

								{receiptData?.discounts?.map(discount => (
									<Row>
										<Col sm={9}>
											Discount ({discount.promoCode})
										</Col>
										<Col sm={3}>
											<DiscountRow {...discount} />
										</Col>
									</Row>
								))}
							</Row>
							<hr />
							<Row>
								<Col sm={9}>
									<Span thick>Total</Span>
								</Col>
								<Col sm={3}>
									$ {((receiptData?.total || 0) / 100).toFixed(2)}
								</Col>
							</Row>
							<hr />
							<Row style={{ flexDirection: 'column' }}>
								<Text>
									We'll send you an email with your items! We appreciate your business, and hope you enjoy your purchase!
								</Text>
								<Text thick>
									Thank you!
								</Text>
								<Text>
									Discord Dungeons
								</Text>
							</Row>
						</CardContent>
						<CardFooter className={styles.footer}>
							<Row>
								<Col sm={8} style={{ padding: 0 }}>
									<Text>Questions? Contact our <a href="mailto:support@discorddungeons.me">Customer Support</a></Text>
								</Col>
								<Col sm={4} style={{ padding: 0 }}>
									<Text light>&copy; 2021 Discord Dungeons</Text>
								</Col>
							</Row>
						</CardFooter>
					</Card>
				</Col>
			</Row>
		</StorePage>
	)
}

export default SuccessPage
